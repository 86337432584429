.gradient-footer {
    --padding-top: 40px;
    --padding-bottom: 20px;

    --gradient-color: var(
        --zz-footer-gradient-color,
        var(--ion-color-app-background-rgb)
    ); // This should be the RGB value of the color

    position: fixed;
    bottom: 0;
    width: 100%;
    padding: var(--padding-top) 20px calc(max(env(safe-area-inset-bottom), var(--padding-bottom))) 20px;
    background: linear-gradient(
        to top,
        rgba(var(--gradient-color), 1) 0%,
        rgba(var(--gradient-color), 1) 65%,
        rgba(var(--gradient-color), 0) 100%
    );
}
